import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import {
	Container,
	Row,
	Col,
	ListGroup,
	Badge,
	Card,
	Tabs,
	Tab,
} from "react-bootstrap";

import ImageOne from "../../images/9.jpg";
import ImageTwo from "../../images/14.jpg";
import ImageThree from "../../images/15.jpg";
import ImageFour from "../../images/16.jpg";
import ImageFive from "../../images/18.jpg";
import ImageSix from "../../images/19.jpg";

import ImageSeven from "../../images/24.jpg";
import ImageNine from "../../images/26.jpg";
import ImageTen from "../../images/27.jpg";
import ImageEleven from "../../images/28.jpg";
import ImageThirteen from "../../images/30.jpg";
import ImageFourteen from "../../images/31.jpg";
import ImageFifthteen from "../../images/32.jpg";
import ImageSixteen from "../../images/17.jpg";
import ImageSeventeen from "../../images/33.jpg";

import Calendar from "react-calendar";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import fetch from "node-fetch";
import { Link } from "gatsby";
import ReactImageGallery from "react-image-gallery";

const images = [
	{
		original: ImageOne,
		originalAlt: "The lounge area.",
		originalWidth: 800,
		originalHeight: 600
	},
	{
		original: ImageSeventeen,
		originalAlt: "The lounge area.",
		originalWidth: 800,
		originalHeight: 600
	},
	{
		original: ImageTwo,
		originalAlt: "The kitcken and dining area.",
		originalWidth: 800,
		originalHeight: 600
	},
	{
		original: ImageThree,
		originalAlt: "The bathroom.",
		originalWidth: 800,
		originalHeight: 571
	},
	{
		original: ImageFour,
		originalAlt: "The master bedroom.",
		originalWidth: 600,
		originalHeight: 800
	},
	{
		original: ImageSixteen,
		originalAlt: "The master bedroom.",
		originalWidth: 600,
		originalHeight: 800
	},
	{
		original: ImageFive,
		originalAlt: "The wood burning stove in the lounge area.",
		originalWidth: 800,
		originalHeight: 600
	},
	{
		original: ImageSix,
		originalAlt: "Complimentary items and access to a fully equiped kitchen.",
		originalWidth: 760,
		originalHeight: 556
	},
	{
		original: ImageSeven,
		originalAlt: "Our guestbook.",
		originalWidth: 450,
		originalHeight: 602
	},
	{
		original: ImageNine,
		originalAlt: "Views of the Tamar Valley, just a short walk away.",
		originalWidth: 700,
		originalHeight: 700
	},
	{
		original: ImageTen,
		originalAlt: "View of Kit Hill.",
		originalWidth: 700,
		originalHeight: 700
	},
	{
		original: ImageEleven,
		originalAlt: "The crossing.",
		originalWidth: 560,
		originalHeight: 700
	},
	{
		original: ImageThirteen,
		originalAlt: "Sunset on the valley.",
		originalWidth: 800,
		originalHeight: 600
	},
	{
		original: ImageFourteen,
		originalAlt: "Outside seating area.",
		originalWidth: 800,
		originalHeight: 600
	},
	{
		original: ImageFifthteen,
		originalAlt: "The cows living in the neighboring field.",
		originalWidth: 700,
		originalHeight: 700
	},
];

export default class RhyllaCottage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			dates: [],
			maxDate: undefined,
		};

		// this.tileClassName = this.tileClassName.bind(this);
	}

	// eslint-disable-next-line react/no-deprecated
	componentDidMount() {
		// https://github.com/crashspringfield/react-google-calendar/blob/master/src/utils/googleAPI.js%20https://www.googleapis.com/calendar/v3/calendars/xyz@group.calendar.google.com/events?timeMin={date.format(%22yyyy-MM-dd%27T%2700:00:00Z%22)}&timeMax={date.addMonths(6).format(%22yyyy-MM-dd%27T%2723:59:59Z%22)}&singleEvents=true

		let date = new Date();
		let today = date.toISOString();

		let sixMonths = new Date(date.getFullYear(), date.getMonth() + 10, 0);
		let endDate = sixMonths.toISOString();
		let dates = [];
		// DEV
		// return fetch(`https://content.googleapis.com/calendar/v3/calendars/j34n2no9aqkdcf0l6kkhfjgdm8@group.calendar.google.com/events?timeMax=${endDate}&timeMin=${today}&key=AIzaSyB0VHrsAhkV-b9KPi68YDYmEO-aAleYyPk`)
		// PRODUCTION
		return fetch(
			`https://content.googleapis.com/calendar/v3/calendars/fmmr0ec6ttfhsnl4l91p42tb9g@group.calendar.google.com/events?timeMax=${endDate}&timeMin=${today}&key=AIzaSyDm-4GgwIB-mfhDcnC_J6wTjxJFvVmBWLU`
		)
			.then((res) => res.json())
			.then((res) => {
				const items = res.items;

				for (const _date of items) {
					let start;
					let end;
					let tmp;

					if (_date.start.date) {
						start = new Date(_date.start.date);
					} else {
						tmp = new Date(_date.start.dateTime);
						start = new Date(
							tmp.getFullYear(),
							tmp.getMonth(),
							tmp.getDate()
						);
					}

					if (_date.end.date) {
						end = new Date(_date.end.date);
					} else {
						tmp = new Date(_date.end.dateTime);
						end = new Date(
							tmp.getFullYear(),
							tmp.getMonth(),
							tmp.getDate()
						);
					}

					dates.push({ start, end });
				}
				this.setState({
					dates: dates,
					maxDate: sixMonths,
					loaded: true,
				});
			});
	}

	render() {
		let calendar = <p>Loading calendar...</p>;
		if (this.state.loaded) {
			calendar = (
				<Calendar
					style={{ width: "100%" }}
					nextLabel={<FaChevronRight></FaChevronRight>}
					prevLabel={<FaChevronLeft></FaChevronLeft>}
					next2Label={null}
					prev2Label={null}
					minDate={new Date()}
					maxDate={this.state.maxDate}
					showNeighboringMonth={false}
					tileClassName={({ date, view }) => {
						if (view === "month") {
							let day = date.getDate();
							let month = date.getMonth();
							let year = date.getFullYear();

							var today = new Date();
							if (date <= today) {
								return "red";
							}

							for (const range of this.state.dates) {
								let _startDate = range.start;
								let _startDay = _startDate.getDate();
								let _startMonth = _startDate.getMonth();
								let _startYear = _startDate.getFullYear();

								let _endDate = range.end;
								let _endDay = _endDate.getDate();
								let _endMonth = _endDate.getMonth();
								let _endYear = _endDate.getFullYear();

								if (
									day == _startDay &&
									month == _startMonth &&
									year == _startYear
								) {
									let foundYesterday = this.state.dates.findIndex(
										(_yesterdayRange) => {
											if (
												date.getDate() ==
													_yesterdayRange.end.getDate() &&
												date.getMonth() ==
													_yesterdayRange.end.getMonth() &&
												date.getFullYear() ==
													_yesterdayRange.end.getFullYear()
											) {
												// if(dayBefore >= _yesterdayRange.start && dayBefore <= _yesterdayRange.end){
												return true;
											}
										}
									);

									if (foundYesterday == -1) {
										// console.log('date is NOT end date');
										// Day is not an end date
										// console.log('start day')
										return "start";
									} else {
										// Day is also an end date
										// console.log('also end date / handover')
										return "red";
									}
								} else if (
									day == _endDay &&
									month == _endMonth &&
									year == _endYear
								) {
									let foundTomorrow = this.state.dates.findIndex(
										(_tomorrowRange) => {
											if (
												date.getDate() ==
													_tomorrowRange.start.getDate() &&
												date.getMonth() ==
													_tomorrowRange.start.getMonth() &&
												date.getFullYear() ==
													_tomorrowRange.start.getFullYear()
											) {
												return true;
											}
										}
									);

									if (foundTomorrow == -1) {
										return "end";
									} else {
										return "red";
									}
								} else if (
									date > _startDate &&
									date < _endDate
								) {
									return "red";
								}
							}
						}
					}}
					formatShortWeekday={(locale, date) =>
						date.toLocaleString(locale, { weekday: "narrow" })
					}
				/>
			);
		}

		return (
			<Layout>
				<SEO title="Cottage" />
				<div className="jumbotron">
					<Container>
						<Row style={{ marginBottom: 24 }}>
							<Col>
								<Row>
									<Col md={true} sm={8}>
										<h1>Rhylla Cottage</h1>
									</Col>
									<Col md={true} sm={8} className="badges">
										<h2>
											<Badge className="info-badge">
												From £80 / night
											</Badge>
											&nbsp;
											<Badge className="info-badge">
												2 Guests
											</Badge>
										</h2>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row style={{ marginBottom: 16 }}>
							<Col>
								<Row>
									<Col>
										<h3>About Rhylla Cottage</h3>
										<p>
											Rhylla Cottage has been recently
											renovated, adding a contemporary
											style to this historic cottage. The
											cottage benefits from its own
											private parking and private garden
											space, ideal to take in the stunning
											view of the Tamar valley, with a
											drink in hand, after a day exploring
											the area.
										</p>
										<p>
											The front door enters on ground
											level and provides a space for your
											well-trodden walking boots and
											wellies! The cottage features a
											newly-fitted modern shower room and
											a well-equipped traditional style
											kitchen with dining/breakfast
											seating area.
										</p>
										<p>
											Going down the original granite
											stairs and opening up into the large
											lounge with 2 comfy sofas, tv, and
											woodburner, the views again become
											apparent from the double aspect
											windows, making this room the heart
											of the cottage. From the lounge you
											enter the bedroom with king size bed
											with quality bedding and ample
											hanging/storage space.
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row style={{ marginBottom: 48 }}>
							<Col>
								<ReactImageGallery
									lazyLoad={true}
									showThumbnails={false}
									items={images}
								/>
							</Col>
						</Row>
						<Row style={{ marginBottom: 48 }}>
							<Col>
								<Tabs
									variant="pills"
									defaultActiveKey="amenities"
									style={{
										paddingLeft: "1rem",
										fontWeight: 500,
									}}
								>
									<Tab eventKey="amenities" title="Amenities">
										<Row>
											<Col>
												<ListGroup variant="flush">
													<ListGroup.Item>
														Modern kitchen
													</ListGroup.Item>
													<ListGroup.Item>
														Dishwasher
													</ListGroup.Item>
													<ListGroup.Item>
														Modern shower room
													</ListGroup.Item>
													<ListGroup.Item>
														TV
													</ListGroup.Item>
													<ListGroup.Item>
														Log burner (first basket
														of logs included)
													</ListGroup.Item>
												</ListGroup>
											</Col>
											<Col>
												<ListGroup variant="flush">
													<ListGroup.Item>
														WiFi
													</ListGroup.Item>
													<ListGroup.Item>
														King sized bed
													</ListGroup.Item>
													<ListGroup.Item>
														Private parking
													</ListGroup.Item>
													<ListGroup.Item>
														Private garden
													</ListGroup.Item>
													<ListGroup.Item>
														Bed linen and shower
														room towels provided
													</ListGroup.Item>
												</ListGroup>
											</Col>
										</Row>
									</Tab>
									<Tab eventKey="location" title="Location">
										<Row>
											<Col md={4}>
												<address
													style={{
														padding:
															"8px 16px 16px 16px",
													}}
												>
													Rhylla
													<br />
													Townlake
													<br />
													Tavistock
													<br />
													Devon
													<br />
													PL19 8PQ
												</address>
											</Col>
											<Col md={8}>
												<iframe
													width="100%"
													height="300"
													frameBorder="0"
													src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d896.2876886686203!2d-4.2545015584180135!3d50.551200232673!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486c8b3e96e1f555%3A0x774c5326e85069fb!2sRhylla%20Cottage%20%26%20Rhylla%20Retreat!5e0!3m2!1sen!2sus!4v1648585720357!5m2!1sen!2sus"
													allowFullScreen
												></iframe>
											</Col>
										</Row>
									</Tab>
								</Tabs>
							</Col>
						</Row>
						<Card>
							<Card.Header as="h5">Availability</Card.Header>
							<Card.Body>
								<Row>
									<Col md={8} style={{ textAlign: "center" }}>
										{calendar}
										<i>*3 night minimum stay</i>
									</Col>
									<Col md={4}>
										<h5
											style={{
												marginTop: "16px",
												marginBottom: "16px",
											}}
										>
											Interested in booking or have an
											enquiry?
										</h5>
										<p>
											Please contact us for up to date
											prices and late availability special
											offers.
										</p>
										<Link
											to="/contact-us"
											className="btn btn-primary btn-lg"
										>
											Contact Us
										</Link>
										<br />
										<br />
										<Link to="https://www.airbnb.co.uk/rooms/44399531">
											Or view our Airbnb listing.
										</Link>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Container>
				</div>
			</Layout>
		);
	}
}
